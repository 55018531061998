<template>
  <div class="ReportBox">
    <TitleHeader msg="厂检报告"></TitleHeader>
    <!-- <el-row>
        <el-col :span="8"><el-input
    placeholder="请输入生产企业/商品ID/商品名称/批文号"
    v-model="input" clearable>
    <i slot="prefix" class="el-input__icon el-icon-search"  aria-hidden="true"></i>
  </el-input></el-col>
    </el-row> -->
   <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :data="tableData"
      class="wraper"
      style="width: 100%;"
    >
      
      <el-table-column 
      class="text-center"
        prop="name"
        label="商品名称"
        width="180">
      </el-table-column>
         <el-table-column 
         class="text-center"
        prop="page"
        label="规格">
      </el-table-column>
      <el-table-column 
      class="text-center"
        prop="product"
        label="生产企业"
        width="180">
      </el-table-column>
      <el-table-column 
      class="text-center"
        prop="date"
        label="批号">
      </el-table-column>
   
      <el-table-column 
      class="text-center"
        label="检验报告">
        <template>
        <el-button type="text" size="small">立即下载</el-button>
      </template>
      </el-table-column>
    </el-table>
        <el-pagination
            background
            layout="prev, pager, next"
            :hide-on-single-page="false"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            :current-page="1"
            :total="60">
        </el-pagination>
  </div>
</template>

<script>
 const TitleHeader = ()=>import("components/common/TitleHeader.vue");
  export default {
    name:"Report",
    data(){
        return{
            input:'',
            loading: false,
            tableData: [{
            num:'17347454',
            date: '2016-05-02',
            name: '王小虎',
            product:"山西省复盛公",
            address: '立即下载',
            page:1
          }, {
            num:'17347454',
            date: '2016-05-04',
            name: '王小虎',
            product:"山西省复盛公",
            address: '立即下载',
             page:1
          }, {
            num:'17347454',
            date: '2016-05-01',
            name: '王小虎',
            product:"山西省复盛公",
            address: '立即下载',
             page:1
          }, {
            num:'17347454',
            date: '2016-05-03',
            name: '王小虎',
            product:"山西省复盛公",
            address: '立即下载',
             page:1
          }]
        }
    },
    methods:{
     search(){
         this.loading=true;
         setTimeout(()=>{
             this.loading=false;
         },100)
     }
  },
   created(){
     
    },
    components:{
        TitleHeader
    }
  }
</script>
<style lang="less">
@import "~style/theme.less";

/* 表格样式 */
.ReportBox .el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #fff !important;
}
.ReportBox .el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
.ReportBox .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: none;
}
/* th样式 */
.ReportBox .el-table th {
  background-color: #eee;
  text-align: center;
}
/* td样式 */
.ReportBox .el-table thead .cell {
  text-align: center;
    font-size: 13px;
    color:#666;
}
.ReportBox .el-table tbody .cell {
  text-align: center;
    font-size:12px;
    color:#333;
}
    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: @theme; 
        color: #fff;
    }
/* 上一页，下一页样式 */
.el-pagination button, .el-pagination span:not([class*=suffix]){
    background: #fff;
    color: #666;
     padding: 4px;
     box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li{
    background: #fff;
    color: #666;
    padding: px;
    box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover{
    color:@theme; 
    font-size: 14px;
}
</style>
<style lang="less" scoped>
@import '~style/index.less';
 .wraper {
     margin-top: 30px;
     .el-table thead {
         background: red;
     }
 }
 .el-pagination {
     padding-top: 40px;
     text-align: center
     }
    
 
</style>

